@import "med-erp-variables";

.di-tx-center{
    text-align: center;
}
.di-tx-right{
    text-align: right;
}


.mat-mdc-button.mat-mdc-button-base, 
.mat-mdc-raised-button.mat-mdc-button-base, 
.mat-mdc-unelevated-button.mat-mdc-button-base, 
.mat-mdc-outlined-button.mat-mdc-button-base{
    height: 30px;
}
.ngx-datatable.material{
    .datatable-body .datatable-body-row .datatable-body-cell{
        padding: 0.2rem 0.6rem;
    }
    .datatable-footer .datatable-pager li.active a{
        background-color: $di-primary;
    }
    .datatable-header .datatable-header-cell{
        color: $di-primary;
        padding: 0.9rem 0.6rem;
        font-size: 15px;
    }
    .datatable-row-even{
        background-color: rgba($color: $di-primary, $alpha: 0.06);
    }
    &:not(.cell-selection) .datatable-body-row:hover, 
    &:not(.cell-selection) .datatable-body-row:hover .datatable-row-group{
        background: linear-gradient(90deg, rgba($color: $di-primary, $alpha: 0.07) 0%, rgba($color: $di-primary, $alpha: 0.2) 100%);
    }
    .datatable-footer .page-count{
        color: $di-primary;
    }
    .datatable-header .datatable-header-cell.di-tx-center,
    .datatable-body .datatable-body-row .datatable-body-cell.di-tx-center{
        text-align: center;
    }
}
mat-form-field{
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
        top: 18px;
    }
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
        padding-top: 8px;
        padding-bottom: 4px;
        min-height: 30px;
    }
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY( -28px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
        transform: var(--mat-mdc-form-field-label-transform);
    }
    .mat-mdc-icon-button.mat-mdc-button-base{
        width: 36px;
        height: 36px;
        padding: 0px;
    }
    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple{
        border-radius: 3%;
    }
    input:read-only{
        opacity: 0.75;
    }
}
.navbar{
    .navbar-brand{
        img{
            height: 40px;
        }
    }
    .container-fluid, .container{
        background: linear-gradient(90deg, rgba($color: $di-primary, $alpha: 0.09) 0%, rgba($color: $di-primary, $alpha: 0.3) 100%);
    }
}
section {
    &.content {
      margin: 60px 15px 0 270px;
    }
  }
.mdc-dialog.mat-mdc-dialog-container{
    height: auto;
}